<template>
  <div>
    <NModal
      preset="card"
      :mask-closable="false"
      :close-on-esc="false"
      size="small"
      style="width: 850px;"
      :title="modalTitle"
      v-model:show="modalDisplay"
      @after-leave="handleModalClosed"
    >
      <template #default>
        <NForm size="small" inline label-placement="left">
          <NFormItem label="账号/所属学校：">
            <NInput v-model:value="formValue.name" clearable />
          </NFormItem>
          <NFormItem label="最近访问时间：">
            <NDatePicker
              type="daterange"
              style="width: 275px;"
              v-model:value="formValue.dateRange"
              clearable
            />
          </NFormItem>
          <NFormItem>
            <NButton type="primary" size="small" @click="handleSearch">搜索</NButton>
            <NButton size="small" style="margin-left: 10px;" @click="handleClearSearch">清除</NButton>
          </NFormItem>
        </NForm>
        <NDataTable
          size="small"
          :columns="tableColumns"
          :data="tableData"
          :pagination="tablePagination"
          remote
        ></NDataTable>
      </template>
      <template #footer>
        <div style="text-align: right;">
          <NButton type="primary" size="small" @click="handleClickConfirm">关闭</NButton>
        </div>
      </template>
    </NModal>

    <PageLoading :loading="loading" />
  </div>
</template>
<script setup>
  import { ref, reactive, h } from 'vue';
  import { NModal, NForm, NFormItem, NInput, NDatePicker, NDataTable, NTime, NButton } from 'naive-ui';

  import PageLoading from '@/components/PageLoading/index.vue';

  import { resStatusEnum } from '@/enumerators/http.js';

  import { accessStatistics } from '@/api/recommend.js';

  const { SUCCESS } = resStatusEnum;
  const loading = ref(false);

  let recommendID;
  const modalDisplay = ref(false);
  const modalTitle = ref('');

  function getDefaultFormValue() {
    return {
      name: '',
      dateRange: null
    };
  }
  const formValue = reactive(getDefaultFormValue());
  function resetFormValue() {
    Object.assign(formValue, getDefaultFormValue());
  }
  function getSearchParams() {
    const obj = {};
    const name = formValue.name.trim();
    if (name) {
      obj.system_name = name;
    }
    if (formValue.dateRange) {
      obj.start_time = formValue.dateRange[0] / 1000;
      obj.end_time = formValue.dateRange[1] / 1000;
    }
    return obj;
  }
  function handleSearch() {
    handlePageChange(1);
  }
  function handleClearSearch() {
    resetFormValue();
    handleSearch();
  }

  const tableColumns = [
    { title: '账号', key: 'admin_username', align: 'center' },
    { title: '所属学校', key: 'system_name', align: 'center' },
    { title: '累计次数', key: 'browse_num', align: 'center', width: '95px' },
    {
      title: '最近访问时间',
      align: 'center',
      width: '175px',
      render: ({ update_time }) => h(
        NTime,
        { time: update_time * 1000 }
      )
    }
  ];
  const tableData = ref([]);
  const tablePagination = reactive({
    page: 1,
    pageSize: 10,
    pageSizes: [10, 20, 30],
    showSizePicker: true,
    itemCount: 0,
    onChange: handlePageChange,
    onUpdatePageSize: pageSize => {
      tablePagination.pageSize = pageSize;
      handlePageChange(1);
    }
  });
  function getPaginationParams() {
    const { page, pageSize: page_size } = tablePagination;
    return {
      page,
      page_size
    }
  }
  function updateItemCount(count) {
    tablePagination.itemCount = Number(count) || 0;
  }
  function handlePageChange(page) {
    tablePagination.page = page;
    updateTableData();
  }
  function updateTableData() {
    loading.value = true;
    accessStatistics({
      id: recommendID,
      ...getSearchParams(),
      ...getPaginationParams()
    }).then(({ code, data }) => {
      if (code === SUCCESS) {
        const { list, total } = data;
        tableData.value = Array.isArray(list) ? list : [];
        updateItemCount(total);
        modalDisplay.value || (modalDisplay.value = true);
      }
    }).finally(() => {
      loading.value = false;
    });
  }

  function handleModalClosed() {
    
  }
  function handleClickConfirm() {
    modalDisplay.value = false;
  }

  defineExpose({
    open({ title, id }) {
      recommendID = id;
      modalTitle.value = `${title} 访问统计`;
      handleClearSearch();
    }
  });
</script>