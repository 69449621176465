<template>
  <div style="background-color: #fff; padding: 20px;">
    <NForm inline label-placement="left">
      <NFormItem label="关键词：">
        <NInput
          maxlength="50"
          show-count
          clearable
          v-model:value="formValue.keywords"
        />
      </NFormItem>
      <NFormItem label="类型：">
        <NSelect
          style="width: 130px;"
          :options="typeOptions"
          clearable
          v-model:value="formValue.type"
        />
      </NFormItem>
      <NFormItem>
        <NButton type="primary" @click="handleSearch">搜索</NButton>
        <NButton style="margin-left: 10px;" @click="handleClearSearch">清除</NButton>
      </NFormItem>
    </NForm>
    <div v-if="ownUrlPermission('recommend/create')" style="margin: -10px 0 10px; text-align: right;">
      <NButton type="primary" @click="handleClickAdd">添加推荐</NButton>
    </div>
    <NDataTable
      size="small"
      :columns="tableColumns"
      :data="tableData"
      :pagination="tablePagination"
      remote
    ></NDataTable>

    <AddModal ref="addModalRef" @list-update="updateTableData" />
    <SortChangeModal ref="sortChangeModalRef" @list-update="updateTableData" />
    <AccessStatisticsModal ref="accessStatisticsModalRef" />
    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, reactive, h } from 'vue';
  import { NForm, NFormItem, NSelect, NInput, NDataTable, NTime, NSpace, NButton, useMessage, useDialog } from 'naive-ui';

  import PageLoading from '@/components/PageLoading/index.vue';
  import AddModal from './components/AddModal.vue';
  import SortChangeModal from './components/SortChangeModal.vue';
  import AccessStatisticsModal from './components/AccessStatisticsModal.vue';

  import { resStatusEnum } from '@/enumerators/http.js';
  import { resourceTypeValNameMap } from './resource-type.js';
  import ownUrlPermission from '@/utils/own-url-permission.js';

  import { recommendList, recommendDel } from '@/api/recommend.js';

  const { SUCCESS } = resStatusEnum;
  const loading = ref(false);
  const message = useMessage();
  const dialog = useDialog();

  const typeOptions = Object.keys(resourceTypeValNameMap).map(val => ({ label: resourceTypeValNameMap[val], value: Number(val) }));
  function getDefaultFormValue() {
    return {
      keywords: '',
      type: null
    };
  }
  const formValue = reactive(getDefaultFormValue());
  function resetFormValue() {
    Object.assign(formValue, getDefaultFormValue());
  }
  function getSearchParams() {
    const obj = {};
    const keywords = formValue.keywords.trim();
    if (keywords) {
      obj.title = keywords;
    }
    if (formValue.type) {
      obj.type = formValue.type;
    }
    return obj;
  }
  function handleSearch() {
    handlePageChange(1);
  }
  function handleClearSearch() {
    resetFormValue();
    handleSearch();
  }

  const addModalRef = ref(null);
  function handleClickAdd() {
    addModalRef.value.open();
  }

  const sortChangeModalRef = ref(null);
  const accessStatisticsModalRef = ref(null);

  const tableColumns = [
    { title: '顺序', key: 'sort', width: '95px', align: 'center' },
    { title: '标题', key: 'title', align: 'center' },
    {
      title: '类型',
      align: 'center',
      render: ({ type }) => h('span', null, resourceTypeValNameMap[type])
    },
    {
      title: '添加时间',
      align: 'center',
      render: ({ create_time }) => h(NTime, { time: create_time * 1000 })
    },
    { title: '浏览次数', key: 'browse_num', align: 'center' },
    {
      title: '操作',
      width: '225px',
      align: 'center',
      render: rowData => {
        const { id, title } = rowData;
        const arr = [];
        if (ownUrlPermission('recommend/log')) {
          arr.push(h(
            NButton,
            { type: 'primary', text: true, onClick: () => { accessStatisticsModalRef.value.open(rowData) } },
            { default: () => '访问统计' }
          ));
        }
        if (ownUrlPermission('recommend/sort')) {
          arr.push(h(
            NButton,
            { type: 'primary', text: true, onClick: () => { sortChangeModalRef.value.open(rowData) } },
            { default: () => '更改排序' }
          ));
        }
        if (ownUrlPermission('recommend/delete')) {
          arr.push(h(
            NButton,
            {
              type: 'error',
              text: true,
              onClick: () => {
                dialog.warning({
                  title: '取消推荐确认',
                  content: `确定取消推荐：“${title}”？`,
                  positiveText: '确定',
                  negativeText: '取消',
                  onPositiveClick: () => {
                    console.log('取消推荐');
                    loading.value = true;
                    recommendDel({
                      'Recommend[type_status]': 1,
                      'Recommend[id]': id
                    }).then(({ code }) => {
                      if (code === SUCCESS) {
                        message.success(`已取消推荐：“${title}”`);
                        updateTableData();
                      }
                    }).finally(() => {
                      loading.value = false;
                    });
                  }
                })
              }
            },
            { default: () => '取消推荐' }
          ));
        }
        return h(
          NSpace,
          { size: [7, 0] },
          { default: () => arr }
        );
      }
    },
  ];
  const tableData = ref([]);
  const tablePagination = reactive({
    page: 1,
    pageSize: 10,
    pageSizes: [10, 20, 30],
    showSizePicker: true,
    itemCount: 0,
    onChange: handlePageChange,
    onUpdatePageSize: pageSize => {
      tablePagination.pageSize = pageSize;
      handlePageChange(1);
    }
  });
  function getPaginationParams() {
    const { page, pageSize: page_size } = tablePagination;
    return {
      page,
      page_size
    }
  }
  function updateItemCount(count) {
    tablePagination.itemCount = Number(count) || 0;
  }
  function handlePageChange(page) {
    tablePagination.page = page;
    updateTableData();
  }
  function updateTableData() {
    loading.value = true;
    recommendList({
      ...getSearchParams(),
      ...getPaginationParams()
    }).then(({ code, data }) => {
      if (code === SUCCESS) {
        const { list, total } = data;
        tableData.value = Array.isArray(list) ? list : [];
        updateItemCount(total);
      }
    }).finally(() => {
      loading.value = false;
    });
  }

  updateTableData();
</script>