<template>
  <div>
    <NModal
      preset="card"
      :mask-closable="false"
      :close-on-esc="false"
      size="small"
      style="width: 350px;"
      :title="modalTitle"
      v-model:show="modalDisplay"
      @after-leave="handleModalClosed"
    >
      <template #default>
        <NForm
          style="margin: 30px 10px 20px 0;"
          ref="formRef"
          label-placement="left"
          require-mark-placement="left"
          :rules="formRule"
          :model="formValue"
        >
          <NFormItem label="排序：" path="sort">
            <NInput v-model:value="formValue.sort" clearable @blur="handleSortBlur" />
          </NFormItem>
        </NForm>
      </template>
      <template #footer>
        <div style="text-align: right;">
          <NButton size="small" @click="closeModal">取消</NButton>
          <NButton
            style="margin-left: 10px;"
            type="primary"
            size="small"
            @click="handleClickConfirm"
          >确定</NButton>
        </div>
      </template>
    </NModal>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, reactive } from 'vue';
  import { NModal, NForm, NFormItem, NInput, NButton, useMessage } from 'naive-ui';

  import PageLoading from '@/components/PageLoading/index.vue';

  import { resStatusEnum } from '@/enumerators/http.js';

  import { recommendSortChange } from '@/api/recommend.js';

  const emit = defineEmits(['list-update']);

  const { SUCCESS } = resStatusEnum;
  const loading = ref(false);
  const message = useMessage();

  let recommendID;
  const modalDisplay = ref(false);
  const modalTitle = ref('');

  function getDefaultFormValue() {
    return {
      sort: ''
    };
  }
  const formValue = reactive(getDefaultFormValue());
  function resetFormValue() {
    Object.assign(formValue, getDefaultFormValue());
  }
  const formRule = {
    sort: [{ required: true, message: '必填', trigger: 'blur' }]
  }
  function formatSort(val) {
    val = parseFloat(val);
    if (val || val === 0) {
      val = Math.round(val);
      val < 0 && (val = 0);
      val > 9999 && (val = 9999);
      return String(val);
    } else {
      return '';
    }
  }
  function handleSortBlur() {
    formValue.sort = formatSort(formValue.sort);
  }

  function handleModalClosed() {
    resetFormValue();
  }
  function closeModal() {
    modalDisplay.value = false;
  }
  
  const formRef = ref(null);

  function handleClickConfirm() {
    formRef.value.validate(errors => {
      if (!errors) {
        loading.value = true;
        recommendSortChange({
          'Recommend[id]': recommendID,
          'Recommend[sort]': formValue.sort
        }).then(({ code }) => {
          if (code === SUCCESS) {
            emit('list-update');
            message.success(`${modalTitle.value}成功`);
            closeModal();
          }
        }).finally(() => {
          loading.value = false;
        });
      }
    });
  }

  defineExpose({
    open({ title, id, sort }) {
      recommendID = id;
      modalTitle.value = `${title} 更改排序`;
      formValue.sort = formatSort(sort);
      modalDisplay.value = true;
    }
  });
</script>