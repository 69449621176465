<template>
  <div>
    <NModal
      preset="card"
      :mask-closable="false"
      :close-on-esc="false"
      size="small"
      style="width: 750px;"
      title="添加推荐"
      v-model:show="modalDisplay"
      @after-leave="handleModalClosed"
    >
      <template #default>
        <NForm size="small" inline label-placement="left">
          <NFormItem label="名称：">
            <NInput v-model:value="formValue.keyword" clearable />
          </NFormItem>
          <NFormItem label="类型">
            <NSelect
              style="width: 130px;"
              :options="typeOptions"
              v-model:value="formValue.type"
            />
          </NFormItem>
          <NFormItem>
            <NButton type="primary" size="small" @click="handleSearch">搜索</NButton>
            <NButton size="small" style="margin-left: 10px;" @click="handleClearSearch">清除</NButton>
          </NFormItem>
        </NForm>
        <NDataTable
          size="small"
          :columns="tableColumns"
          :data="tableData"
          :pagination="tablePagination"
          remote

          :row-key="({ id }) => `${formValue.type}-${id}`"
          @update:checked-row-keys="handleCheckTableRow"
        ></NDataTable>
      </template>
      <template #footer>
        <div style="text-align: right;">
          <NButton size="small" @click="closeModal">取消</NButton>
          <NButton
            type="primary"
            size="small"
            style="margin-left: 10px;"
            @click="handleClickConfirm"
            :disabled="selectedRowKeys.length === 0"
          >（已选：{{ selectedRowKeys.length }}）提交</NButton>
        </div>
      </template>
    </NModal>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, reactive, h } from 'vue';
  import { NModal, NForm, NFormItem, NInput, NSelect, NDataTable, NButton, useMessage } from 'naive-ui';

  import PageLoading from '@/components/PageLoading/index.vue';

  import { resStatusEnum } from '@/enumerators/http.js';
  import { resourceTypeValNameMap } from '../resource-type.js';

  import { recommendResourceList, recommendAdd } from '@/api/recommend.js';

  const emit = defineEmits(['list-update']);

  const { SUCCESS } = resStatusEnum;
  const loading = ref(false);
  const message = useMessage();

  const modalDisplay = ref(false);

  const typeOptions = Object.keys(resourceTypeValNameMap).map(val => ({ label: resourceTypeValNameMap[val], value: Number(val) }));
  function getDefaultFormValue() {
    return {
      keyword: '',
      type: typeOptions[0].value
    };
  }
  const formValue = reactive(getDefaultFormValue());
  function resetFormValue() {
    Object.assign(formValue, getDefaultFormValue());
  }
  function getSearchParams() {
    const obj = {};
    const keyword = formValue.keyword.trim();
    if (keyword) {
      obj.base_name = keyword;
    }
    if (formValue.type) {
      obj.type = formValue.type;
    }
    return obj;
  }
  function handleSearch() {
    handlePageChange(1);
  }
  function handleClearSearch() {
    resetFormValue();
    handleSearch();
  }

  const tableColumns = [
    { type: 'selection', width: '45px' },
    { title: '标题', key: 'base_name', align: 'center' },
  ];
  const tableData = ref([]);
  const tablePagination = reactive({
    page: 1,
    pageSize: 10,
    pageSizes: [10, 20, 30],
    showSizePicker: true,
    itemCount: 0,
    onChange: handlePageChange,
    onUpdatePageSize: pageSize => {
      tablePagination.pageSize = pageSize;
      handlePageChange(1);
    }
  });
  function getPaginationParams() {
    const { page, pageSize: page_size } = tablePagination;
    return {
      page,
      page_size
    }
  }
  function updateItemCount(count) {
    tablePagination.itemCount = Number(count) || 0;
  }
  function handlePageChange(page) {
    tablePagination.page = page;
    updateTableData();
  }
  function updateTableData() {
    loading.value = true;
    recommendResourceList({
      ...getSearchParams(),
      ...getPaginationParams()
    }).then(({ code, data }) => {
      if (code === SUCCESS) {
        const { list, total } = data;
        tableData.value = Array.isArray(list) ? list : [];
        updateItemCount(total);
        modalDisplay.value || (modalDisplay.value = true);
      }
    }).finally(() => {
      loading.value = false;
    });
  }

  const selectedRowKeys = ref([]);
  function handleCheckTableRow(rowKeys) {
    selectedRowKeys.value = rowKeys;
  }

  function handleModalClosed() {
    
  }
  function closeModal() {
    modalDisplay.value = false;
  }
  function handleClickConfirm() {
    loading.value = true;
    recommendAdd({
      Recommend: selectedRowKeys.value.map(item => {
        const arr = item.split('-');
        return {
          type: arr[0],
          source_id: arr[1]
        }
      })
    }).then(({ code }) => {
      if (code === SUCCESS) {
        emit('list-update');
        message.success('添加成功');
        closeModal();
      }
    }).finally(() => {
      loading.value = false;
    });
  }

  defineExpose({
    open() {
      selectedRowKeys.value.splice(0);
      handleClearSearch();
    }
  });
</script>