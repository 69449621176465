import request from '@/utils/request.js';
import { reqMethodEnum } from '@/enumerators/http.js'
const { GET, POST } = reqMethodEnum;

export function recommendList(data) {
  return request({
    url: '/backend/web/recommend/index',
    method: GET,
    params: data
  });
}

export function recommendResourceList(data) {
  return request({
    url: '/backend/web/recommend/source-list',
    method: GET,
    params: data
  });
}

export function recommendAdd(data) {
  return request({
    url: '/backend/web/recommend/create',
    method: POST,
    data
  });
}

export function recommendDel(data) {
  return request({
    url: '/backend/web/recommend/delete',
    method: POST,
    data
  });
}

export function recommendSortChange(data) {
  return request({
    url: '/backend/web/recommend/sort',
    method: POST,
    data
  });
}

export function accessStatistics(data) {
  return request({
    url: '/backend/web/recommend/log',
    method: GET,
    params: data
  });
}